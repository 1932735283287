#private-page-content {
  min-height: calc(100vh - 170px);
}

a:hover {
  text-decoration: none;
}

.hover-red:hover {
  color: var(--red);
}

.list-group-item .row {
  /*min-height: 40px;*/
}

.no-spacing {
  line-height: 1rem;
}

.text-highlight {
  color: var(--red);
}

.img-short img {
  min-width: 100%;
  min-height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.container-fluid > .row {
  margin-bottom: 1.6em;
}

.text-center-right > * {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.editing .view-data {
  visibility: hidden;
}

not(.editing) .view-data {
  visibility: visible;
}

.editing .edit-data {
  display: initial;
}

:not(.editing) .edit-data {
  display: none;
}

:not(.editing) .view-data {
  display: flex;
}

.editing .view-data {
  display: none;
}

.editing .edit-data {
  display: flex;
}

.form-control,
.edit-data input,
.edit-data .progress,
.edit-data button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.text-middle > * {
  position: relative;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
}

.manager .hide-manager {
  display: none;
}

.user .only-manager {
  display: none;
}

.img-short {
  overflow: hidden;
}

.card {
  margin-bottom: 1.6rem;
}

.user-list-item img {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

/* .card-header h6 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
} */

:not(.disabled) .show-disabled {
  display: none;
}

.disabled .hide-disabled {
  display: none;
}

.disabled .show-disabled {
  display: initial;
}

.scroll-to-top.hidden {
  visibility: hidden;
}

.card-header form * {
  margin: 0;
}

.MuiAutocomplete-root {
  background-color: white;
}

.snapshot-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.snapshot-container .item {
  flex: 1;
  padding: 5px 0;
}

.edit-data .MuiAutocomplete-root input {
  margin: 0;
}

.edit-data .MuiAutocomplete-endAdornment {
  top: auto;
}

.table-filters-checkbox-container {
  padding-top: 1em;
}

.input-section-checkbox-container {
  padding-right: 1em;
}

.day-checkbox {
  padding-right: 0;
  padding-bottom: 0.5em;
}

.input-section-checkbox {
  width: 20px;
  height: 20px;
  margin-top: 2px !important;
}

.table-row-checkbox input {
  margin-top: 0 !important;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.face-profiles-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  overflow: hidden;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.face-profiles-container img {
  padding: 16px;
}

.text-sm {
  font-size: 12.6px;
}

@media (min-width: 992px) {
  .face-profiles-container {
    grid-template-columns: auto auto;
  }
  .face-profiles-container img:nth-of-type(3),
  .face-profiles-container img:nth-of-type(4) {
    display: none;
  }
}

@media (min-width: 1150px) {
  .face-profiles-container {
    grid-template-columns: auto auto auto;
  }
  .face-profiles-container img:nth-of-type(3) {
    display: unset;
  }
  .face-profiles-container img:nth-of-type(4) {
    display: none;
  }
}

@media (min-width: 1500px) {
  .face-profiles-container img:nth-of-type(4) {
    display: unset;
  }
  .face-profiles-container {
    grid-template-columns: auto auto auto auto;
  }
}
