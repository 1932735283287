.bg-gradient-primary {
  color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df, 10%, #224abe);
  background-size: cover;
}

.unauthorized-page .form-control {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 1.5rem 1rem;
}

.unauthorized-page button {
  font-size: 0.8em;
  border-radius: 10em;
  padding: 0.92em;
}
