.switch {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  right: 0;
  bottom: 0;
  margin-bottom: -4px;
}

/* Hide default HTML checkbox */

.switch input {
  display: none;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.6rem;
  width: 0.6rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input.default:checked + .slider {
  background-color: #444;
}

input.primary:checked + .slider {
  background-color: #2196f3;
}

input.success:checked + .slider {
  background-color: #8bc34a;
}

input.info:checked + .slider {
  background-color: #3de0f5;
}

input.warning:checked + .slider {
  background-color: #ffc107;
}

input.danger:checked + .slider {
  background-color: #f44336;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1rem);
  -ms-transform: translateX(1rem);
  transform: translateX(1rem);
}

/* Rounded sliders */

.slider.round {
  border-radius: 0.5rem;
}

.slider.round:before {
  border-radius: 50%;
}
